<template>
  <div class=" "  > 
    <div class=" " ref="declareContainer">
      <div class=" ">  
        <div v-if="tabsValue == 'baseInfo'" class="d-base-contianer">
          <div class="b-title">基本信息</div>
          <TwoColumnTable  :items="tableBaseInfo"></TwoColumnTable>
          <div class="b-title">经办人信息</div>
          <TwoColumnTable :items="tableAgentInfo"></TwoColumnTable> 
          <div v-if="formContent">
            <div class="b-title" @click="fillData">项目信息</div>
            <ParseFormViewDiv
              ref="parseFormViewDiv"
              :formData="formContent"
              size="100%"
              readonly="true"
            ></ParseFormViewDiv>
          </div>
        </div>
        <div v-if="tabsValue == 'fujian'" class="d-base-contianer" >
          <TableRanking
            :itemList="materialList" 
            @uploadFileSuccess="uploadFileSuccess"
            :isShowBtn="false"
          ></TableRanking>
        </div>
        <div class="d-btns" id="btns"> 
        </div>
      </div> 
    </div>
  </div>
</template>

<script> 
import TwoColumnTable from "@/components/twoColumnTable.vue";
import TableRanking from "@/components/declareComponents/tableRanking.vue"; 
import ParseFormViewDiv from "@/components/declareComponents/formMaking/ParseFormViewDiv";
import { Loading } from "element-ui";
import { mapGetters } from "vuex";
export default {
  components: {
    ParseFormViewDiv,
    TableRanking, 
    TwoColumnTable,
  },
  computed: {
    ...mapGetters({
      loginUserInfo: "getUserInfo",
      idCardTypes: "getIdCardTypes",
      projectItem: "getCurrentProjectItem", // 从我的项目进入时有值
      childItem: "getCurrentDeclareItem",
      gsBaseInfo: "getAccountBaseInfo",
    }),
  },
  props: {
    tabsValue: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      sxmc: "",
      showFloatingBar: false, 
      formData: null, // formData是请求回来的原始数据
      formContent: null, // 根据formContent去渲染页面
      bstype: "1", // 申报类型 1企业2个人 根据用户信息是否有社会信用代码判断
      tabs: [
        {
          label: "基本信息",
          name: "baseInfo",
        },
        {
          label: "附件清单",
          name: "fujian",
        },
      ],
      enterpriseInfo: {
        unitname: "",
        unitcardtype: "身份证", // 机构证件类型
        unitcardcode: "", // 统一信用代码
        legalperson: "", //法人名字
        legalcardtype: "", // 法人身份证
        legalcardnum: "", //法人身份证号
        unitaddress: "",
        unittel: "",
        unitpostcode: "",
        unitemail: "",
      },
      tableBaseInfo: [], // 基本信息
      tableAgentInfo: [], // 经办人基本信息
      materialList: [], // 网络请求回来的材料列表数据
      workUpList: [], //  通过工作台传的上传
      materials: [], // 与materialList结构一样，上传后增加files字段，如果没上传过材料 提交申报时就用materialList的值
      currentBusinessInfo: null, //从我的项目进入申报页面，获取到是申报的数据
    };
  },
  created() {
    
  },
  mounted() {
    console.log("childItem:", this.childItem);
    console.log("projectItem:", this.projectItem);
    console.log("loginUserInfo:", this.loginUserInfo);
    console.log("gsBaseInfo", this.gsBaseInfo); 
    // 增加滚动监听， 显示申报按钮
    window.addEventListener("scroll", this.handleScroll);
    // 获取证照类型
    if (!this.idCardTypes) {
      console.log("获取词典");
      this.$store.dispatch("getDictListByType", {
        dictType: "sys_id_card_type",
      });
    }
    if (true) {
      // 申报类型 1企业2个人
      this.bstype = 1;
    } else {
      this.bstype = 2;
    }
    if (this.projectItem) {
      this.sxmc = this.projectItem.name || this.projectItem.sxmc;
      if (this.projectItem.status == 6) {
        //暂存件
        this.initTempSaveData();
      } else {
        //其他情况的
        // 如果从我的项目进入则返回所有数据
        this.getBusinessInfo();
      }
    } else {
      this.sxmc = this.childItem.name || this.childItem.sxmc;
      // 获取表单的数据 并渲染
      this.loadFromData();
      // 获取材料的数据
      this.loadMaterialList();
    }

    this.initBaseInfoUI();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
     fillData() {
      let data = {
        dwmc: "深圳太极云软技术有限公司",
        zcsj: "2020-09-01",
        txdz: "深圳新一代产业园3栋1402",
        email: "513@qq.com",
        zczj: "8000",
        sbbh: "12345678",
        nsbm: "0X78782376672",
        fddb: "徐某某",
        bgdh1: "0755-54367819",
        sj1: "13058058350",
        lxr: "陈某某",
        bgdh2: "0755-46567819",
        sj2: "13058058351",
        khyh: "78238378712887263",
        yhzh: "4762638273888712878",
        djlx: 7,
        fclx: 2,
        xmmc: "低碳节能减排",
        xmtze: 800,
        sssj: "2021-01-27T16:00:00.000Z",
        xmgm: "项目规模项目规模很大",
        xmnr: "这是项目内容项目内容",
      };
      if (this.$refs.parseFormViewDiv) {
        this.$refs.parseFormViewDiv.fillFormData(this.formContent, data);
      }
    },
    // 更新基本信息、经办人信息UI
    initBaseInfoUI() {
        // this.enterpriseInfo = {
        //   unitname:   "某某",
        //   unitcardtype: "10*****08", // 机构证件类型  100008是工商营业执照
        //   unitcardcode:   "****", // 统一信用代码
        //   legalperson:  "****", //法人名字
        //   legalcardtype: "100001", // 法人证件类型 100001是身份证
        //   legalcardnum:   "************", //法人身份证
        //   unitaddress:   "****",
        //   unittel:  "****",
        //   unitpostcode: "000000",
        //   unitemail:   "****",
        // };
        // 企业申报
        //  用于显示企业的基本信息
        this.tableBaseInfo = [
          { title: "机构名称", content: "暂无" },
          { title: "申报对象", content: "单位" },
          { title: "机构电话", content: "***********" },
          { title: "机构证件号码", content: "***************" },
          { title: "法人姓名", content: "暂无" },
          { title: "法人证件号码", content: "***************" },
        ]; 
        // 登录人就是经办人 tableAgentInfo数组用于显示经办人的信息
        this.tableAgentInfo = [
          { title: "经办人姓名", content:  "暂无" },
          { title: "经办人电话", content:  "185********" },
          {
            title: "经办人证件类型",
            content:  "身份证",
          },
          {
            title: "经办人证件号码",
            content:  "430********0101",
          }, 
        ]; 
    },
    // 从我的办件进入时 获取办件详情信息
    getBusinessInfo() {
      if (!this.projectItem) {
        return;
      }
      let loadingInstance = Loading.service({
        target: this.$refs.declareContainer,
      });
      this.$nextTick(() => {
        let formData = new FormData();
        formData.append("id", this.projectItem.id);
        this.$httpApi
          .post("/dev-api/userBusiness/getBusinessInfo", formData)
          .then((res) => {
            loadingInstance.close();
            console.log("getBusinessInfo>>>>>>>", res);
            if (res.code == 200) {
              this.initBusinessInfoData(res);
            }
          })
          .catch((err) => {
            loadingInstance.close();
          });
      });
    },
    // 获取到的办件详情进行渲染
    initBusinessInfoData(res) {
      let resData = JSON.parse(JSON.stringify(res.data.material));
      // 保存下请求的值
      this.currentBusinessInfo = res.data;

      // 事项名称
      if (res.data.masterinfo) {
        this.currentBusinessInfo.masterinfo.bsnum = res.data.masterinfoReporter.bsnum;
      }

      /* 附件清单，从我的工作台进去的 */
      let target = [];
      for (const item of resData) {
        item.dgVisible = false;
        item.files = item.masterinfoList;
        for (const i in item.files) {
          let f = item.files[i];
          f.name = f.filename || f.fileName;
        }
        target.push(item);
      }
      console.log("上传相关，从我的工作台进去的>>>", target);
      // TODO: materialList为空数据不好吧
      this.materialList = [];
      this.workUpList = target;

      this.formData = res.data.formData;
      if (this.formData) {
        // 解析表单显示UI
        this.formContent = JSON.parse(this.formData.formdata);
        // 填充表单
        setTimeout(() => {
          const data = JSON.parse(this.formData.subdata);
          console.log("填充的表单数据:", data);
          this.fillFormData(data);
        }, 500);
      }
    },
    initTempSaveData() {
      console.log("initTempSaveData:", this.projectItem);
      // 给材料列表赋值
      let materialList = this.projectItem.bsdata.materials;
      /* 附件清单，从我的工作台进去的 */
      let target = [];
      for (const item of materialList) {
        item.dgVisible = false;
        item.files = item.masterinfoList || [];
        for (const i in item.files) {
          let f = item.files[i];
          f.name = f.filename || f.fileName;
        }
        target.push(item);
      }
      console.log("上传相关，从我的工作台进去的>>>", target);
      // TODO: materialList为空数据不好吧
      this.materialList = [];
      this.workUpList = target;

      // 渲染表单
      this.formData = this.projectItem.bsdata.formInfo[0];
      if (this.formData) {
        // 解析表单显示UI
        this.formContent = JSON.parse(this.formData.formdata);
        // 填充表单
        setTimeout(() => {
          const data = JSON.parse(this.formData.subdata);
          console.log("填充的表单数据:", data);
          this.fillFormData(data);
        }, 500);
      }
    },
    /* 上传的文件信息 */
    uploadFileSuccess(e) {
      console.log("upload OK: ", e);
      this.materials = e;
    },
    // 加载表单的json数据，进行渲染表单
    loadFromData() {
      if (this.childItem) {
        if (!this.childItem.formid) {
          console.log("----------------- 表单ID不能为空 -----------------");
          return;
        }
        let loadingInstance = Loading.service({
          target: this.$refs.declareContainer,
        });
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.$httpApi
            .get(`/dev-api/basicinfomanage/info/${this.childItem.formid}`, {})
            .then((res) => {
              console.log("res表单数据>>>:", res);
              loadingInstance.close();
              if (res.code == 200) {
                this.formData = res.data;
                if (this.formData.formContent) {
                  this.formContent = JSON.parse(this.formData.formContent);
                  // console.log("处理后的表单数据>>>" + JSON.stringify(this.formContent));
                  setTimeout(() => {
                    this.fillFormData();
                  }, 500);
                }
              }
            })
            .catch((err) => {
              loadingInstance.close();
            });
        });
      }
    },
    //
    fillFormData(data) {
      if (data == null) {
        // 假数据 这数据应该是从后台请求到的
        data = this.gsBaseInfo;
      }
      if (this.$refs.parseFormViewDiv) {
        this.$refs.parseFormViewDiv.fillFormData(this.formContent, data);
      }
    },
    loadMaterialList() {
      if (this.childItem) {
        // http://192.9.206.22:8199/prod-api/dev-api/basicinfo/clxx/list?itemId=0425335001394fe1b6064baf1e4606f1&pageNum=1&pageSize=100
        this.$httpApi
          .getByBody("/dev-api/basicinfo/clxx/list", {
            itemId: this.childItem.id, // 事项ID
            pageNum: "1",
            pageSize: "100",
          })
          .then((res) => {
            console.log("loadMaterialList:", res);
            if (res.code == 200) {
              let resData = res.rows;
              let target = [];
              for (const item of resData) {
                item.dgVisible = false;
                item.clmc = item.clname; // 材料名称
                item.isneed = item.sfby; // 是否必须
                // item.clbm = "202012150101"; // 材料编码
                target.push(item);
              }
              // console.log("上传相关>>>", target);
              this.materialList = target;
            }
          })
		  .catch((err) => {
		    
		  });
      }
    },
    handleSubmitData(isTempSave, projectFormData) {
      let submitData = {};
      if (this.projectItem && this.projectItem.status == 6) {
        let bsdata = this.projectItem.bsdata;
        let formInfo = [
          {
            bsnum: this.formData.bsnum || "", // 业务流水号
            id: this.formData.id || "", // 业务表单ID 新申报时为空
            formdata: JSON.stringify(this.formContent), // 这个可以不传
            subdata: projectFormData ? JSON.stringify(projectFormData) : "",
            formtype: this.formData.formtype || "0", // 0 业务表单；1 在线填报表单,11:文书表单,10基本表单，12特别程序表单
            formid: this.formData.formid,
          },
        ];
        //如果有修改了附件的 则使用数组this.materials，否则使用网络请求的附件数组
        let materials;
        if (this.materials && this.materials.length > 0) {
          materials = this.materials;
        } else {
          materials = this.materialList.length > 0 ? this.materialList : this.workUpList;
        }
        submitData = {
          baseinfo: bsdata.baseinfo, // 基本信息
          formInfo: formInfo, // 表单信息
          materials: materials, // 材料信息
          operator: bsdata.operator,
        };
        console.log("从待办件进来后的要提交的数据:", submitData);
        return submitData;
      } else {
        let childItem;
        if (this.currentBusinessInfo && this.currentBusinessInfo.masterinfo) {
          // 从在办件进入masterinfo才有值
          childItem = this.currentBusinessInfo.masterinfo;
        } else {
          childItem = this.childItem;
        }

        let formInfo = [
          {
            bsnum: childItem.bsnum || "", // 业务流水号
            id: this.formData.id || "", // 业务表单ID 新申报时为空
            formdata: JSON.stringify(this.formContent), // 这个可以不传
            subdata: projectFormData ? JSON.stringify(projectFormData) : "",
            formtype: childItem.formtype || "0", // 0 业务表单；1 在线填报表单,11:文书表单,10基本表单，12特别程序表单
            formid: childItem.formid,
          },
        ];

        let baseInfo = {
          areaid: childItem.regionCode || "",
          areaname: childItem.regionName || "",
          sxid: childItem.id || "", // 事项ID
          bsnum: childItem.bsnum || "",
          bstype: childItem.bstype || this.bstype || "", // 业务类型1企业2个人
          cnsj: childItem.cnsj || "", // 承诺时间
          cnsjdw: childItem.cnsjdw || "", //承诺时间单位
          deptid: childItem.deptCode || childItem.deptid || "",
          deptname: childItem.deptName || childItem.deptname || "",
          sxmc: childItem.name || childItem.sxmc || "", // 事项名称
          jljb: childItem.jljb || "", // 是否即办件
          flowid: childItem.flowId || childItem.flowId || "", // 办理流程ID
          applicantid: childItem.applicantid || "", // 申请人ID
          appname: childItem.appname || "", // 申请人姓名
          appcompany: childItem.appcompany || "", // 申请人公司

          type: childItem.handerType || "", // 业务来源 1PC 2窗口
          status: childItem.status || "", // 业务办理状态 0在办1正常办结2异常办结3补交4特别程序5预受理
          ssgzr: childItem.ssgzr || "", // 所属工作日
        };
        // 经办人信息
        let agentInfo = {
          agentuserid: this.loginUserInfo.id,
          agentname: this.loginUserInfo.name || "",
          agentcertificatetype: "100001",
          agentcertificatename: "身份证",
          agentcertificatenumber: this.loginUserInfo.idcard || "",
          agentmobilephone: this.loginUserInfo.phone || "",
          agentgender: "2", //性别
          agentbirthday: this.loginUserInfo.birthday || "",
          agentaddress: this.loginUserInfo.address || "",
          agentemail: this.loginUserInfo.email || "",
        };

        // 申报人信息
        let reporter = {
          operateid: this.loginUserInfo.id || "",
          operateaccount: this.loginUserInfo.loginId || "",
          optname: this.loginUserInfo.name || "",
          optcertificatetype: "100001",
          optcertificatename: "身份证",
          optcertificatenumber: "",
          optmobilephone: this.loginUserInfo.phone || "",
          optgender: "1",
          optbirthday: this.loginUserInfo.birthday || "",
          optaddress: this.loginUserInfo.address || "",
          optemail: this.loginUserInfo.email || "",
        };
        // 再次申报的时候 要用到currentBusinessInfo的值进行赋值
        if (this.currentBusinessInfo) {
          reporter = this.currentBusinessInfo.masterinfoReporter;
        }

        //如果有修改了附件的 则使用数组this.materials，否则使用网络请求的附件数组
        let materials;
        if (this.materials && this.materials.length > 0) {
          materials = this.materials;
        } else {
          materials = this.materialList.length > 0 ? this.materialList : this.workUpList;
        }

        submitData = {
          baseinfo: baseInfo, // 基本信息
          formInfo: formInfo, // 表单信息
          materials: materials, // 材料信息
          operator: {
            apptype: this.bstype, // 申报类型 1企业 2个人
            enterprise: this.enterpriseInfo, // 企业信息
            agent: agentInfo, // 经办人信息
            reporter: reporter,
          },
        };
        console.log("提交的参数:", submitData);
        return submitData;
      }
    },
    async submitAction(isTempSave) {
      let projectFormData = null;
      if (isTempSave) {
        projectFormData = await this.$refs.parseFormViewDiv.getTempSaveData();
      } else {
        projectFormData = await this.$refs.parseFormViewDiv.getFormData();
      }

      if (projectFormData == null && !isTempSave) {
        // 暂存时不校验数据
        if (this.tabsValue != "baseInfo") {
          // 先切换为表单baseInfo页面，让用户知道哪些信息该填
          this.tabsValue = "baseInfo";
        }
        this.$message({
          message: "请先填写必填信息",
          type: "error",
        });
        return;
      }
      let submitData = this.handleSubmitData(isTempSave, projectFormData);
      const loading = Loading.service({
        fullscreen: true,
      });
      let url = "/dev-api/business/handle/report";
      if (isTempSave) {
        url = "/dev-api/business/handle/zancun";
      }
      this.$httpApi
        .post(url, submitData)
        .then((res) => {
          loading.close();
          console.log("提交表单结果:", res);
          if (res.code == 200) {
            this.$message({
              message: isTempSave ? "暂存成功" : "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$router.replace({ path: "/workplace/index" });
            }, 1500);
          } else {
            this.$message({
              message: "提交失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          loading.close();
          this.$message({
            message: "网络异常，请稍后重试",
            type: "error",
          });
        });
    },
    tempSave() {
      // console.log("暂存");
      this.submitAction(true);
    },
    checkInput() {
      console.log("checkInput");
    },

    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let btnsoffsetTop = document.querySelector("#btns").offsetTop;
      // console.log("btn:", scrollTop, btnsoffsetTop, window);
      if (scrollTop > btnsoffsetTop - window.innerHeight) {
        this.showFloatingBar = false;
      } else {
        this.showFloatingBar = true;
      }
    },

    savepdf() {
      console.log("start loading。。。");
      let vm = this;
      const A4_WIDTH = 592.28;
      const A4_HEIGHT = 841.89;
      vm.$nextTick(async () => {
        let target = vm.$refs.pdf;
        vm.getPdf(target, "文件名");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.declare {
  // background: #fff;
  .banner-size {
    background: url("/assets/images/icon_banner_declare.png");
  }
  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;
    .banner-other {
      // margin-left: 15px;
    }
  }
}
.declare-container {
  background-color: #ffffff;
  .d-container {
    // padding: 30px 24px;
    .d-title {
      font-size: 24px;
      color: #000;
      font-weight: 600; 
    }
  }
  .d-btns { 
  }
}

.d-base-contianer { 
  width: 100%;
  // border: 1px solid #eee;
  // box-shadow: 0 0 10px 2px #eee;
  // border-radius: 4px; 
  .b-title {
    padding: 6px 0; 
    margin-bottom: 10px;
    border-bottom: 2px solid #333;
    color: #333;
    font-size: 14px;
    font-weight: 600;
  }
}
.floatingBar {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 9999;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: all 0.2s ease;
}
</style>

<template>
  <div class="guideCenter" v-if="childItem != null"> 
    <!-- 基本信息 -->
    <item-cell id="jibenxinxi" class="" style="width: 1000px" title="基本信息">
      <!-- <div>基本信息</div> -->
      <TwoColumnTable :items="baseInfo"></TwoColumnTable> 
    </item-cell> 
    <item-cell id="banliliucheng" title="办理流程">
      
		<div>
			<el-steps class="steps-container" direction="vertical" :active="4" process-status="error">

        <!-- 1. 申请前 -->
				<el-step title="申请前">
					<div slot="description" class="liucheng-login">  
            <!-- collapse 折叠面板 -->
            <el-collapse>
              <!-- 申请资格 -->
              <el-collapse-item title="1. 申请资格" name="1">
                <div class="mx-text ">{{ childItem.acceptCondition }}</div> 
              </el-collapse-item> 
              <!-- 注册账户 -->
              <el-collapse-item title="2. 注册账户" name="2">
                <div class="mx-text ">项目申报时，需要进行登录，所以您可以在项目申报前<a @click="toRegistered()">注册网站账户</a>，并牢记账号密码。</div> 
              </el-collapse-item> 
            </el-collapse>
					</div>
				</el-step>

        <!-- 2. 申请手续 -->
				<el-step title="申请手续">
					<div slot="description" class="liucheng-form"> 
            <!-- collapse 折叠面板 -->
            <el-collapse>
              <!-- 申请资格 -->
              <el-collapse-item title="1. 登录账户" name="1">
                <el-button  type="primary" round class="btn"  @click="toLogin()">{{ loginStatus }}</el-button>
              </el-collapse-item> 
              <!-- 申请表格 -->
              <el-collapse-item title="2. 申请表格" name="2"> 
                <ShowDeclare tabsValue="baseInfo"></ShowDeclare>			
              </el-collapse-item> 
              <!-- 材料清单 -->
              <el-collapse-item title="3. 材料清单" name="3">
                <div class="mx-text">以下为申报本事项所需的材料列表及相关要求，如您已经备齐相关附件，可点击跳转至材料列表页面上传相关附件完成电报</div> 
							  <ShowDeclare tabsValue="fujian"></ShowDeclare> 			
              </el-collapse-item> 
              <!-- 递交申请 -->
              <el-collapse-item title="4. 递交申请" name="4">
                <div class="mx-text ">您在填写完表单信息，上传完必要材料并检查无误，可点击提交申请，如您需要再考虑细节，可点击暂存按钮，以方便下次继续申报</div>
              </el-collapse-item> 
              <!-- 审批时间 -->
              <el-collapse-item title="5. 审批时间" name="5">
                <div class="mx-text ">法定时间：<span style="color: rgba(64, 158, 255)"><b>{{ childItem.limitDays}}</b>天</span></div>
                <div class="mx-text ">承诺时间：<span style="color: rgba(64, 158, 255)"><b>{{ childItem.permitDays}}</b>天</span></div>
              </el-collapse-item> 
            </el-collapse> 
					</div> 
				</el-step>

        <!-- 3. 申报后 -->
				<el-step title="申报后"  >
					<div slot="description" class="liucheng-cl"> 
            <!-- collapse 折叠面板 -->
            <el-collapse>
              <!-- 查看进度 -->
              <el-collapse-item title="1. 查看进度" name="1">
                <div class="mx-text ">如你已经完成申报，需要了解办理详情及项目办理的最新进展，可点击进入用户中心进行进度查材料补交等操作 <a @click="tojindu()">（点击查看）</a></div>
              </el-collapse-item> 
              <!-- 电话咨询 -->
              <el-collapse-item title="2. 电话咨询" name="2"> 	
                <div class="mx-text ">咨询电话：{{ childItem.advisoryTel}}</div>		
              </el-collapse-item> 
              <!-- 补交材料 -->
              <el-collapse-item title="3. 补交材料" name="3"> 	
                <div class="mx-text ">当您收到补交信息后，您可以根据补交说明对相关材料进行校对后，进入用户中心，重新提交材料完成补交操作</div>	
              </el-collapse-item>  
            </el-collapse> 
					</div>
				</el-step> 

			</el-steps>
		</div>
    </item-cell>   
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ItemCell from "@/components/guideItemCell";
import TwoColumnTable from "@/components/twoColumnTable";
import ShowDeclare from "./declare"

export default {
  name: "guideCenter",
  components: { 
	  ItemCell, 
	  TwoColumnTable,
	  ShowDeclare 
	},
  props: {
    baseItem: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters({
      childItem: "getCurrentDeclareItem",
    }),
  },
  data() {
    return {
      /* 办理流程 前，中，后 */ 
		  loginStatus: this.$store.getters.getUserInfo ? "您已登录" : "点我登录",
      selectDirection: 0, 
      banjie: null,
      cailiaoKeys: [
        {
          type: "index",
          width: 80,
          align: "center",
          title: "序号",
        },
        {
          title: "材料名称",
          key: "clname",
        },
      ],
      zixunData: {
        columns: [
          {
            title: "区划",
            key: "areaName",
          },
          {
            title: "联系人",
            key: "lxr",
          },
          {
            title: "联系方式",
            key: "tel",
          },
        ],
        data: [],
      },
      baseInfo: [ 
      ],
      sqqd: "",
      windowList: [], // 窗口信息
      sxMaterialList: [], // 事项材料
      zxjdList: [], // 咨询监督
      shedingyijuList: [], // 法律法规
    };
  },
  created() {
    this.getRegistrationStatus().then((res) => {    // 获取申请渠道
      this.handleBaseInfo();                        // 处理基本信息
    });
  },
  mounted() { 
  },
  methods: {
    /* 转跳到注册 */
    toRegistered() {
      this.$router.push({ name: "login" });
    },
		/* 转跳到登录 */
		toLogin() {
			// 判断是否登录，没有则转跳
			if (this.$store.getters.getUserInfo) {  
					this.$message({
						message: '您已经登录',
						type: 'success'
					});
			} else {
				this.$router.push({ name: "login" });
			}
		},
		/* 查看进度 */
		tojindu() {
			// 判断是否登录，没有则转跳
			if (this.$store.getters.getUserInfo) {  
				this.$router.replace({ path: "/workplace/index" });
			} else {
				this.$router.push({ name: "login" });
			}
		}, 
    /**
     * 处理基本信息
     */
    handleBaseInfo() {
      if (this.childItem) { 
        this.zixunData.data = this.childItem.zxfsList;
        let tsinfo = "暂无数据";
        this.baseInfo = [
          { title: "服务事项", content: this.childItem.name || tsinfo },
          { title: "服务类型", content: this.childItem.itemBaseName || tsinfo },
          { title: "申请渠道", content: this.sqqd || tsinfo },
          {
            title: "服务目的",
            content: this.childItem.exerciseContent || tsinfo,
          },
          { title: "服务范围", content: this.childItem.servicescope || tsinfo },
          { title: "服务部门", content: this.childItem.deptName || tsinfo },
          { title: "法定时限", content: this.childItem.limitDays || tsinfo },
          { title: "承诺时限", content: this.childItem.permitDays || tsinfo },
          { title: "咨询电话", content: this.childItem.advisoryTel || tsinfo },
          { title: "办理地点", content: this.childItem.address || tsinfo },
          {
            title: "受理条件",
            content: this.childItem.acceptCondition || tsinfo,
          },
          { title: "运行系统", content: this.childItem.belongSystem || tsinfo },
        ];
      }
    },   
    /**
     * 通用：根据字典类型获取字典信息
     */
    async getDictionaryInfo(el) {
      let response;
      await this.$httpApi
        .get("/dev-api/system/dict/getDictListByType", { dictType: el })
        .then((res) => {
          // console.log("根据字典类型获取字典信息>>>", res)
          // console.log("--------------------------------------------")
          if (res.code == 200 && res.data.length !== 0) {
            response = res;
          } else {
            response = 0;
          }
        })
		.catch((err) => {
		  
		});
      return response;
    },
    /**
     * 处理数据通用
     */
    async getClassWrap(el, title) {
      let endList = [];
      await this.getDictionaryInfo(el).then((res) => {
        if (res === 0) {
          console.log("字典暂无数据>>>");
        } else {
          // 处理数据
          // console.log(`${title || ''}处理前>>>`, res)
          const resList = JSON.parse(JSON.stringify(res.data));
          let target = [];
          for (const item of resList) {
            target.push({
              id: item.sort, // 0
              value: item.key, // "A"
              label: item.label, // "农、林..."
            });
          }
          // console.log(`获取${title || ''}处理后的数据>>>`, target)
          endList = target;
        }
      });
      return endList;
    },
    /**
     * 获取申请渠道
     */
    async getRegistrationStatus() {
      await this.getClassWrap("sys_applyfor_channel", "申请渠道").then(
        (res) => {
          // this.djztOptions = res
          console.log("申请渠道>>>", res);
          let target = "";
          for (const item of res) {
            target += item.label + ", ";
          }
          this.sqqd = target;
        }
      );
    }, 
  },
};
</script>

<style lang="scss" scoped>
.mx-text {
  font-size: 14px;
  font-weight: 400; 
  color: #5f5e5e;
}
/* 按钮 */
.steps-container {
	.btn {  
		// margin-top: 20px; 
		font-size: 16px; 
	}
}
// 用户登录
.liucheng-login,
.liucheng-form,
.liucheng-cl {
	// color: #000;
  width: 800px;
  margin: 10px 0;
  padding: 0 10px; 
  border: 1px solid #eee;
  box-shadow: 0 0 10px 2px #eee;
  border-radius: 4px; 
}
.liucheng-form,
.liucheng-cl { 
}
// 填写表单
.liucheng-form {
	
}
// 材料信息
.liucheng-cl {

}

.guideCenter {
	line-height: 30px;
}  
</style>
<style lang="scss">
/* el 样式 */
.html-box {
  img {
    width: 700px !important;
  }
}
.el-step__main {
  .el-steps.el-step__title, .is-finish {
    color: #000 !important;
    font-size: 22px;
    font-weight: 700; 
  }
}

/* 折叠面板标题 */
.el-collapse-item__header { 
  font-weight: 700;
}

</style>
 
<template>
  <div :id="id" class="item-cell-view">
    <div class="section-header">
      <div class="section-title">{{ title }}</div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "itemCell",
  props: {
    id: {
      // 必须要传id，滚动的时候用到
      type: String,
      required: true,
      default: function () {
        return "";
      },
    },
    title: {
      // 必须要传title
      type: String,
      required: true,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.item-cell-view {
  margin-bottom: 20px;
  .section-header {
    border-bottom: 4px solid #000;
    margin-bottom: 12px;
    .section-title {
      font-size: 20px;
      color: rgb(13, 28, 40);
      font-weight: 600;
      height: 46px;
      line-height: 46px;
    }
  }
}
</style>